<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.0002 5.8335C10.4604 5.8335 10.8335 6.20659 10.8335 6.66683C10.8335 7.12707 10.4604 7.50016 10.0002 7.50016C9.53993 7.50016 9.16683 7.12707 9.16683 6.66683C9.16683 6.20659 9.53993 5.8335 10.0002 5.8335Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.0003 8.54199C10.3455 8.54199 10.6253 8.82181 10.6253 9.16699V14.167C10.6253 14.5122 10.3455 14.792 10.0003 14.792C9.65515 14.792 9.37533 14.5122 9.37533 14.167V9.16699C9.37533 8.82181 9.65515 8.54199 10.0003 8.54199Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.51192 2.43892C4.44683 2.58208 3.80427 2.85436 3.32885 3.32902C2.85436 3.80395 2.58209 4.44637 2.43892 5.5116C2.29332 6.59498 2.29199 8.0185 2.29199 10.0003C2.29199 11.9822 2.29332 13.4059 2.43892 14.4893C2.58212 15.5547 2.85446 16.1967 3.32878 16.6707C3.80375 17.1457 4.44632 17.4184 5.51165 17.5616C6.595 17.7073 8.01849 17.7087 10.0003 17.7087C11.9822 17.7087 13.4059 17.7073 14.4892 17.5616C15.5546 17.4183 16.1966 17.1458 16.6706 16.6712C17.1454 16.1967 17.4184 15.5541 17.5616 14.4889C17.7073 13.4057 17.7087 11.9822 17.7087 10.0003C17.7087 8.01848 17.7073 6.59521 17.5616 5.51197C17.4184 4.44692 17.1459 3.80438 16.6709 3.32893M16.6709 3.32893C16.1964 2.85454 15.5541 2.5821 14.4889 2.43892C13.4057 2.29332 11.9822 2.29199 10.0003 2.29199C8.0185 2.29199 6.59519 2.29332 5.51192 2.43892M9.95252 1.04199H10.0481C11.9718 1.04198 13.4793 1.04197 14.6555 1.20006C15.8593 1.36187 16.8093 1.69954 17.5548 2.44505C18.3005 3.19125 18.6385 4.14134 18.8005 5.34535C18.9587 6.52154 18.9587 8.02885 18.9587 9.95245V10.0482C18.9587 11.9718 18.9587 13.4793 18.8005 14.6555C18.6386 15.8593 18.3007 16.8092 17.5549 17.5546C16.8098 18.3007 15.8597 18.6386 14.6558 18.8005C13.4795 18.9587 11.9718 18.9587 10.0481 18.9587H9.95252C8.02888 18.9587 6.52134 18.9587 5.34504 18.8005C4.141 18.6385 3.19091 18.3006 2.44505 17.5548C1.69948 16.8096 1.36185 15.8596 1.20006 14.6558C1.04197 13.4795 1.04198 11.9718 1.04199 10.0481V9.95253C1.04198 8.02887 1.04197 6.52137 1.20006 5.3451C1.36187 4.14113 1.69953 3.19108 2.4449 2.4452C3.19112 1.7 4.14148 1.36188 5.3454 1.20006C6.52157 1.04197 8.02887 1.04198 9.95252 1.04199Z"
    />
  </svg>
</template>
